import React from 'react'
import { createUseStyles } from '../../../helpers/createStyles'
import { theme } from '../../../styles/theme'
import Section from '../../Section'
import PostTile from './PostTile'

import useScrollTrigger from '../../../hooks/useScrollTrigger'

export default function PostTiles ({ data }) {
  const styles = useStyles()
  const { posts, latest, type } = data
  const items = type === 'latest' ? latest : posts

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.inOut',
        duration: 0.8
      }
      tl.from(ref.current.querySelectorAll('article'), { y: 40, opacity: 0, stagger: 0.15, ...defaults })
    }
  )

  return (
    <Section className={styles.container} grid style={{ rowGap: theme.spacing(4) }} ref={sectionRef}>
      {items && items.map((post, index) => (
        <PostTile
          post={post}
          key={index}
          mobileAspect={(index - 1) % 3 === 0 ? 327 / 350 : 156 / 214}
          largeTile={(index - 1) % 3 === 0}
          smallMobile={(index - 1) % 3 !== 0}
        />
      ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  container: {
    '& > article': {
      zIndex: 4
    },
    '& > *:nth-child(1)': {
      order: 2,
      gridColumn: 'span 4',
      [theme.breakpoints.up('md')]: {
        order: 1,
        gridColumn: 'span 4'
      }
    },
    '& > *:nth-child(2)': {
      order: 1,
      gridColumn: 'span 8',
      [theme.breakpoints.up('md')]: {
        order: 2,
        gridColumn: 'span 5'
      }
    },
    '& > *:nth-child(3)': {
      order: 3,
      gridColumn: 'span 4',
      [theme.breakpoints.up('md')]: {
        gridColumn: 'span 3'
      }
    },
    '.dialog-grid &': {
      '& > *:nth-child(1)': {
        [theme.breakpoints.up('md')]: {
          gridColumn: 'span 3'
        }
      }
    }
  }
})
